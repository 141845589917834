import { VIEW_MODE } from '../consts/galleryConsts';

export const getGalleryViewMode = (viewMode: string) => {
  switch (viewMode && viewMode.toLowerCase()) {
    case 'editor':
      return VIEW_MODE.EDIT;
    case 'preview':
      return VIEW_MODE.PREVIEW;
    case 'site':
    default:
      return VIEW_MODE.SITE;
  }
};
