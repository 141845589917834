import React, { useState } from 'react';
import {
  I18nextProvider,
  translate,
  InjectedTranslateProps,
} from 'react-i18next';
import {
  ExperimentsProvider,
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';
import { ExperimentsBag } from '@wix/wix-experiments';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { BILoggerProvider, WixSDK } from 'yoshi-flow-editor-runtime';
import { Button } from 'wix-ui-tpa/Button';
import webBiLogger from '@wix/web-bi-logger';
import i18n from '../../../config/i18n';
import initSchemaLogger from '../../../config/bi';
import styles from './Widget.st.css';
import GogWrapper from './GogWrapper';
import './wixStyles.global.scss';

const biLogger = initSchemaLogger(webBiLogger);

interface WidgetWrapperProps {
  appName: string;
  mobile: boolean;
  language: string;
  translations: Record<string, string>;
  experiments: ExperimentsBag;
  host: any;
  instance: string;
  instanceId: string;
  updateLayout: any;
  viewMode: string;
  setHeight: any;
  albumsCovers: GalleryItem[];
  coverIdToAlbum: { [id: string]: GogAlbum };
  gogData: GogData;
}

export default class extends React.Component<WidgetWrapperProps> {
  render() {
    const {
      experiments,
      language,
      translations,
      mobile,
      host,
      instance,
      instanceId,
      viewMode,
      updateLayout,
      setHeight,
      albumsCovers,
      coverIdToAlbum,
      gogData,
    } = this.props;
    return (
      <BILoggerProvider logger={biLogger}>
        <ExperimentsProvider options={{ experiments }}>
          <TPAComponentsProvider value={{ mobile }}>
            <I18nextProvider i18n={i18n({ language, translations })}>
              <WixSDK>
                {(sdk) => (
                  <Widget
                    Wix={sdk.Wix}
                    host={host}
                    instance={instance}
                    instanceId={instanceId}
                    mobile={mobile}
                    updateLayout={updateLayout}
                    viewMode={viewMode}
                    setHeight={setHeight}
                    albumsCovers={albumsCovers}
                    gogData={gogData}
                    coverIdToAlbum={coverIdToAlbum}
                  />
                )}
              </WixSDK>
            </I18nextProvider>
          </TPAComponentsProvider>
        </ExperimentsProvider>
      </BILoggerProvider>
    );
  }
}

export const Widget = translate()(GogWrapper);
