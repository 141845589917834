import { getGogAlbums } from './gogApi';

const defAlbums: { [id: string]: GogAlbum } = {
  babyLori: {
    albumId: '7e1c4a01-8276-482f-9b55-4ff47b987090',
    dateCreated: '2015-10-06T09:32:57.000Z',
    editables: {
      title: 'Baby Lori',
      date: 'October 18th, 2023',
    },
    photosCount: 203,
    settings: {},
    orderIndex: 6,
    publishedLayout: {
      link: 'http://www.wix.com/exposure4/new-born-bright-p2',
    },
    sets: [
      {
        name: 'Home',
        orderIndex: 1.1,
        photos: [
          {
            orderIndex: 1,
            url: '8bb438_444d9ee4630346ffb891dce4db92df40.jpg',
            photoId: '8bb438_444d9ee4630346ffb891dce4db92df40',
          },
          {
            orderIndex: 2,
            url: '8bb438_df0fed1371e141e2b103425e093de065.jpg',
            photoId: '8bb438_df0fed1371e141e2b103425e093de065',
          },
          {
            orderIndex: 3,
            url: '8bb438_dbdffccb7ab64989b498e227ad577ce2.jpg',
            photoId: '8bb438_dbdffccb7ab64989b498e227ad577ce2',
          },
          {
            orderIndex: 4,
            url: '8bb438_0526340d5bc24aaeae76c96ea6ba49a3.jpg',
            photoId: '8bb438_0526340d5bc24aaeae76c96ea6ba49a3',
          },
          {
            orderIndex: 5,
            url: '8bb438_62f82bbe8cfa499ea8ac895957360998.jpg',
            photoId: '8bb438_62f82bbe8cfa499ea8ac895957360998',
          },
        ],
        setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
        setType: 'cover',
      },
    ],
    status: 'publishsed',
  },
  laurenRandy: {
    albumId: '7e1c4a01-8276-482f-9b55-4ff47b987091',
    dateCreated: '2015-10-06T09:32:57.000Z',
    editables: {
      title: 'Lauren & Randy',
      date: 'October 18th, 2023',
    },
    orderIndex: 1,
    photosCount: 72,
    settings: {},
    publishedLayout: {
      link: 'http://www.wix.com/exposure4/engagement-dark-p2',
    },
    sets: [
      {
        name: 'Home',
        orderIndex: 1.1,
        photos: [
          {
            orderIndex: 1,
            url: '8bb438_d2d70ba209824af49a0624a773729c79.jpg',
            photoId: '8bb438_d2d70ba209824af49a0624a773729c79',
          },
          {
            orderIndex: 2,
            url: '8bb438_07e31b983a2e4271bf5b0d070166429f.jpg',
            photoId: '8bb438_07e31b983a2e4271bf5b0d070166429f',
          },
          {
            orderIndex: 3,
            url: '8bb438_e9348450b83b4480a6c0cca8073e52ce.jpg',
            photoId: '8bb438_e9348450b83b4480a6c0cca8073e52ce',
          },
          {
            orderIndex: 4,
            url: '8bb438_1cc1cb0a82ff4f81903d09dd2b760542.jpg',
            photoId: '8bb438_1cc1cb0a82ff4f81903d09dd2b760542',
          },
          {
            orderIndex: 5,
            url: '8bb438_0a306b3404bd4f0481ab4c323263d2e5.jpg',
            photoId: '8bb438_0a306b3404bd4f0481ab4c323263d2e5',
          },
        ],
        setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
        setType: 'cover',
      },
    ],
    status: 'publishsed',
  },
  familyEverAfter: {
    albumId: '7e1c4a01-8276-482f-9b55-4ff47b987092',
    dateCreated: '2015-10-06T09:32:57.000Z',
    editables: {
      title: 'Family Ever After',
      date: 'October 18th, 2023',
    },
    photosCount: 31,
    settings: {},
    orderIndex: 5,
    publishedLayout: {
      link: 'http://www.wix.com/exposure4/family-bright-p2',
    },
    sets: [
      {
        name: 'Home',
        orderIndex: 1.1,
        photos: [
          {
            orderIndex: 1,
            url: '8bb438_489b5b7b8511461593a50ed7654b997f.jpg',
            photoId: '8bb438_489b5b7b8511461593a50ed7654b997f',
          },
          {
            orderIndex: 2,
            url: '8bb438_629a84cea79242e48bc33ee58012fa71.jpg',
            photoId: '8bb438_629a84cea79242e48bc33ee58012fa71',
          },
          {
            orderIndex: 3,
            url: '8bb438_149dd3f21c7b4f2ea3adb3b9c8016b52.jpg',
            photoId: '8bb438_149dd3f21c7b4f2ea3adb3b9c8016b52',
          },
          {
            orderIndex: 4,
            url: '8bb438_a2b6d7aac3984349af6e92b7ecb9b67f.jpg',
            photoId: '8bb438_a2b6d7aac3984349af6e92b7ecb9b67f',
          },
          {
            orderIndex: 5,
            url: '8bb438_0148d146b0574bb188ab832d07ec4deb.jpg',
            photoId: '8bb438_0148d146b0574bb188ab832d07ec4deb',
          },
        ],
        setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
        setType: 'cover',
      },
    ],
    status: 'publishsed',
  },
  joshuaAshley: {
    albumId: '7e1c4a01-8276-482f-9b55-4ff47b987093',
    dateCreated: '2015-10-06T09:32:57.000Z',
    editables: {
      title: 'Joshua & Ashley',
      date: 'October 18th, 2023',
    },
    orderIndex: 2,
    photosCount: 68,
    settings: {},
    publishedLayout: {
      link: 'http://www.wix.com/exposure4/wedding-bg-dark-p2',
    },
    sets: [
      {
        name: 'Home',
        orderIndex: 1.1,
        photos: [
          {
            orderIndex: 1,
            url: '8bb438_3382db4820744625b02b5aaadd8d75a2.jpg',
            photoId: '8bb438_3382db4820744625b02b5aaadd8d75a2',
          },
          {
            orderIndex: 2,
            url: '8bb438_4f6f8a26e4ab41c4a1b2e968b06acdd2.jpg',
            photoId: '8bb438_4f6f8a26e4ab41c4a1b2e968b06acdd2',
          },
          {
            orderIndex: 3,
            url: '8bb438_f01a67e5eefa4486b753d8109c8118bd.jpg',
            photoId: '8bb438_f01a67e5eefa4486b753d8109c8118bd',
          },
          {
            orderIndex: 4,
            url: '8bb438_e6d80dbad86e482b8be49d48c75243be.jpg',
            photoId: '8bb438_e6d80dbad86e482b8be49d48c75243be',
          },
          {
            orderIndex: 5,
            url: '8bb438_75adf38978fc481c906457c1bf416b3a.jpg',
            photoId: '8bb438_75adf38978fc481c906457c1bf416b3a',
          },
        ],
        setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
        setType: 'cover',
      },
    ],
    status: 'publishsed',
  },
  lynnRichard: {
    albumId: '7e1c4a01-8276-482f-9b55-4ff47b987094',
    dateCreated: '2015-10-06T09:32:57.000Z',
    editables: {
      title: 'Lynn & Richard',
      date: 'October 18th, 2023',
    },
    orderIndex: 3,
    photosCount: 88,
    settings: {},
    publishedLayout: {
      link: 'http://www.wix.com/exposure4/wedding-bg-bright-p2',
    },
    sets: [
      {
        name: 'Home',
        orderIndex: 1.1,
        photos: [
          {
            orderIndex: 1,
            url: '8bb438_35c83f9806594bde9efd314872825f67.jpg',
            photoId: '8bb438_35c83f9806594bde9efd314872825f67',
          },
          {
            orderIndex: 2,
            url: '8bb438_85aefff24c61417e890e07cd16e98365.jpg',
            photoId: '8bb438_85aefff24c61417e890e07cd16e98365',
          },
          {
            orderIndex: 3,
            url: '8bb438_aa0a9ede5cb14013912793d3bb3c1dce.jpg',
            photoId: '8bb438_aa0a9ede5cb14013912793d3bb3c1dce',
          },
          {
            orderIndex: 4,
            url: '8bb438_699fea87f6544f609ad31c2a68b9a134.jpg',
            photoId: '8bb438_699fea87f6544f609ad31c2a68b9a134',
          },
          {
            orderIndex: 5,
            url: '8bb438_c876917b7a9042a4b06aa29959650147.jpg',
            photoId: '8bb438_c876917b7a9042a4b06aa29959650147',
          },
        ],
        setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
        setType: 'cover',
      },
    ],
    status: 'publishsed',
  },
  babyAiden: {
    albumId: '7e1c4a01-8276-482f-9b55-4ff47b987095',
    dateCreated: '2015-10-06T09:32:57.000Z',
    editables: {
      title: 'Baby Aiden',
      date: 'October 18th, 2023',
    },
    orderIndex: 4,
    photosCount: 115,
    settings: {},
    publishedLayout: {
      link: 'http://www.wix.com/exposure4/new-born-dark',
    },
    sets: [
      {
        name: 'Home',
        orderIndex: 1.1,
        photos: [
          {
            orderIndex: 1,
            url: '8bb438_7825f8f4b82b4a37b347935ba87fc3cf.jpg',
            photoId: '8bb438_7825f8f4b82b4a37b347935ba87fc3cf',
          },
          {
            orderIndex: 2,
            url: '8bb438_eab06023896d435080f6afb9d1a08b79.jpg',
            photoId: '8bb438_eab06023896d435080f6afb9d1a08b79',
          },
          {
            orderIndex: 3,
            url: '8bb438_a27fa69350854077b34e52a01f604af6.jpg',
            photoId: '8bb438_a27fa69350854077b34e52a01f604af6',
          },
          {
            orderIndex: 4,
            url: '8bb438_58fd84c4009840078cc840e2a85ea4aa.jpg',
            photoId: '8bb438_58fd84c4009840078cc840e2a85ea4aa',
          },
          {
            orderIndex: 5,
            url: '8bb438_926dbdfcb5de48418792059000ee7f80.jpg',
            photoId: '8bb438_926dbdfcb5de48418792059000ee7f80',
          },
        ],
        setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
        setType: 'cover',
      },
    ],
    status: 'publishsed',
  },
  // rachelSam: {
  //   albumId: '7e1c4a01-8276-482f-9b55-4ff47b987096',
  //   dateCreated: '2015-10-06T09:32:57.000Z',
  //   editables: {
  //     title: 'Rachel & Sam',
  //     date: 'October 18th, 2023',
  //   },
  //   orderIndex: 4,
  //   photosCount: 135,
  // settings: {},
  //   publishedLayout: {
  //     link: 'http://www.wix.com/exposure4/engagement-bright-p2',
  //   },
  //   sets: [
  //     {
  //       name: 'Home',
  //       orderIndex: 1.1,
  //       photos: [
  //         {
  //           orderIndex: 1,
  //           url: '8bb438_25a32cd92d58492cbc78750d1b212de0.jpg',
  //         },
  //         {
  //           orderIndex: 2,
  //           url: '8bb438_2993a2c42e954b63abc450fdab26d441.jpg',
  //         },
  //         {
  //           orderIndex: 3,
  //           url: '8bb438_cba72a99623146ad923e2806f0e84f1f.jpg',
  //         },
  //         {
  //           orderIndex: 4,
  //           url: '8bb438_f620a8dc38254628a0cc12b4b711877b.jpg',
  //         },
  //         {
  //           orderIndex: 5,
  //           url: '8bb438_45bb55b35cb1412e932c5e007d8e89c6.jpg',
  //         },
  //       ],
  //       setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
  //       setType: 'cover',
  //     },
  //   ],
  //   status: 'publishsed',
  // },
  // karaDabato: {
  //   albumId: '7e1c4a01-8276-482f-9b55-4ff47b987097',
  //   dateCreated: '2015-10-06T09:32:57.000Z',
  //   editables: {
  //     title: 'Kara Dabato',
  //     date: 'October 18th, 2023',
  //   },
  //   orderIndex: 4,
  //   photosCount: 135,
  // settings: {},
  //   publishedLayout: {
  //     link: 'http://www.wix.com/exposure4/model-bright-p2',
  //   },
  //   sets: [
  //     {
  //       name: 'Home',
  //       orderIndex: 1.1,
  //       photos: [
  //         {
  //           orderIndex: 1,
  //           url: '8bb438_b9960fffe30649c094a32ca816066ab3.jpg',
  //         },
  //         {
  //           orderIndex: 2,
  //           url: '8bb438_dfef24f99b0940418fb4137702ed7e48.jpg',
  //         },
  //         {
  //           orderIndex: 3,
  //           url: '8bb438_1c3d74dfa2374e28b3a21331ca67fcd8.jpg',
  //         },
  //         {
  //           orderIndex: 4,
  //           url: '8bb438_c4c4c03cf23e4e1ca1bcd1d5261e0794.jpg',
  //         },
  //         {
  //           orderIndex: 5,
  //           url: '8bb438_93b26324e6814c609ce6f0c26c382553.jpg',
  //         },
  //       ],
  //       setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
  //       setType: 'cover',
  //     },
  //   ],
  //   status: 'publishsed',
  // },
  // natashaFink: {
  //   albumId: '7e1c4a01-8276-482f-9b55-4ff47b987098',
  //   dateCreated: '2015-10-06T09:32:57.000Z',
  //   editables: {
  //     title: 'Natasha Fink',
  //     date: 'October 18th, 2023',
  //   },
  //   orderIndex: 4,
  //   photosCount: 135,
  // settings: {},
  //   publishedLayout: {
  //     link: 'http://www.wix.com/exposure4/model-dark-p2',
  //   },
  //   sets: [
  //     {
  //       name: 'Home',
  //       orderIndex: 1.1,
  //       photos: [
  //         {
  //           orderIndex: 1,
  //           url: '8bb438_95a633d5a64147a4896e9564e6691ece.jpg',
  //         },
  //         {
  //           orderIndex: 2,
  //           url: '8bb438_bd98c95dd89d4a8287f99eb686565c60.jpg',
  //         },
  //         {
  //           orderIndex: 3,
  //           url: '8bb438_adee397189054fd79774223a435a3a71.jpg',
  //         },
  //         {
  //           orderIndex: 4,
  //           url: '8bb438_1d6378db77534f5cb466b706dd562813.jpg',
  //         },
  //         {
  //           orderIndex: 5,
  //           url: '8bb438_6142466a30aa4865b83489ff3f984072.jpg',
  //         },
  //       ],
  //       setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
  //       setType: 'cover',
  //     },
  //   ],
  //   status: 'publishsed',
  // },
  // juneRiley: {
  //   albumId: '7e1c4a01-8276-482f-9b55-4ff47b987099',
  //   dateCreated: '2015-10-06T09:32:57.000Z',
  //   editables: {
  //     title: 'June Riley',
  //     date: 'October 18th, 2023',
  //   },
  //   orderIndex: 4,
  //   photosCount: 135,
  // settings: {},
  //   publishedLayout: {
  //     link: 'http://exposure4.wix.com/june-riley',
  //   },
  //   sets: [
  //     {
  //       name: 'Home',
  //       orderIndex: 1.1,
  //       photos: [
  //         {
  //           orderIndex: 1,
  //           url: '8bb438_d3c65c27ef6b4f798ee69b0792601a71.jpg',
  //         },
  //         {
  //           orderIndex: 2,
  //           url: '8bb438_3834e049435f4f41a78063ea4d3eb287.jpg',
  //         },
  //         {
  //           orderIndex: 3,
  //           url: '8bb438_ec80629c0c954a3db6d2977fb65332ba.jpg',
  //         },
  //         {
  //           orderIndex: 4,
  //           url: '8bb438_0c6713f8adb040b2b8d5f945938401c7.jpg',
  //         },
  //         {
  //           orderIndex: 5,
  //           url: '8bb438_0cbcdfd72d7b4b5ba7451744ff101b15.jpg',
  //         },
  //       ],
  //       setId: '97cc32ef-79e2-4cbc-a14e-3a28e57a64d0',
  //       setType: 'cover',
  //     },
  //   ],
  //   status: 'publishsed',
  // },
};

export const getDefaultAlbums = () => {
  const d = defAlbums;
  return [
    d.babyLori,
    // d.rachelSam,
    d.familyEverAfter,
    d.joshuaAshley,
    d.lynnRichard,
    d.babyAiden,
  ];
};

export const getTemplateDefaultAlbums = async (
  defaultAlbumsIds: string[],
  instance: string,
) => {
  let defaultAlbums: GogAlbum[] = [];

  const masterGoG = await getGogAlbums(
    '1a277b49-ad04-4139-a4ab-8b5ac24205a5',
    instance,
    'TPASection_ihuk8wkg',
  );

  if (masterGoG) {
    let id: string, i, gmi;
    const gogMasterHash: { [id: string]: any } = {};

    // map gog master array to hash
    for (gmi = 0; gmi < masterGoG.albums.length; gmi++) {
      const a = masterGoG.albums[gmi];
      gogMasterHash[a.albumId] = a;
    }
    // build array
    for (i = 0; i < defaultAlbumsIds.length; i++) {
      id = defaultAlbumsIds[i];
      if (defAlbums[id]) {
        defaultAlbums.push(defAlbums[id]);
      } else {
        if (gogMasterHash[id]) {
          defaultAlbums.push(gogMasterHash[id]);
        } else {
          console.error(id, 'bad id - not default album or in gog master');
        }
      }
    }
  } else {
    defaultAlbums = getDefaultAlbums();
  }
  return defaultAlbums;
};
