import React from 'react';
import ReactDOM from 'react-dom';

const s = require('./AlbumModal.scss');

// import s from './AlbumModal.scss'  ;

type ModalProps = {
  isOpen: boolean;
  link: string;
  closeModal: any;
};

export default class AlbumModal extends React.Component<ModalProps> {
  // constructor(props: ModalProps) {
  //   super(props);`
  // }

  render() {
    const iframeElement = (
      <div className={s.modalBackground}>
        <div
          className={s.closeButton}
          onClick={() => {
            this.props.closeModal();
          }}
        >
          <svg viewBox="0 0 180 180">
            <path d="M5 5 L175 175 M175 5 L5 175" />
          </svg>
        </div>
        <div
          className={s.externalButton}
          onClick={() => window.open(this.props.link)}
        />
        <div className={s.holdsTheIframe}>
          <iframe
            title="Album Modal"
            className={s.iframe}
            src={this.props.link}
          ></iframe>
        </div>
      </div>
    );
    if (this.props.isOpen && this.props.link) {
      return ReactDOM.createPortal(iframeElement, document.body);
    } else {
      return <div />;
    }
  }
}
